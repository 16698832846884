import React, { useEffect, useState } from 'react';
import { UploadFiles } from '../components/UploadFiles';
import { Dropdown, IDropdownOption, ISpinButtonStyles, IStackItemStyles, IStackProps, Position, SpinButton, Stack, TextField, TooltipHost } from '@fluentui/react';
import { IPDFRow, text_align_enum } from '../components/PdfTools';
import { IFile } from '../types/File';
import { initializeIcons } from '@fluentui/react/lib/Icons';
import {  IIULSign, IulSigns } from '../components/IulSigns';
import { useId } from '@fluentui/react-hooks';
import { YMInitializer } from 'react-yandex-metrika';
import { DownloadFilePdfSelect } from '../components/DownloadFilePDFSelect';

const calloutProps = { gapSpace: 0 };
// The TooltipHost root uses display: inline by default.
// If that's causing sizing issues or tooltip positioning issues, try overriding to inline-block.
export const Iul = props => { 
  useEffect(() => {
    // Обновляем заголовок документа 
    document.title = 'Смета FORTE ИУЛ конструктор';
  });
  
const stackTokens = { childrenGap: 10, maxWidth:1000 };
const spinstyles: Partial<ISpinButtonStyles> = { spinButtonWrapper: { width: 75 } };
const columnProps: Partial<IStackProps> = {
  tokens: { childrenGap: 10, maxWidth:1000 },
};
const stackItemStyles: IStackItemStyles = {
  root: {
    alignItems: 'center',
    justifyContent: 'center',
  },
};
  const tooltipId = useId('tooltip');
  const tooltipId1 = useId('tooltip');
  const tooltipId2 = useId('tooltip');
  const tooltipId3 = useId('tooltip');
  const tooltipId4 = useId('tooltip');
  const tooltipId5 = useId('tooltip');
  const tooltipId6 = useId('tooltip');
  const [Files, getFiles] = useState<IFile[]>([]);
  const [ObjTitle, getObjTitle] = useState(localStorage.getItem("ObjTitle"))
  const [DocCode, getDocCode] = useState(localStorage.getItem("DocCode"))
  const [Chapter, getChapter] = useState(localStorage.getItem("Chapter"))
  const [Part, getPart] = useState(localStorage.getItem("Part"))
  const [FileName, getFileName] = useState(localStorage.getItem("FileName"))
  const [Algoritm, getAlgoritm] = useState(localStorage.getItem("Algoritm")?localStorage.getItem("Algoritm"):'md5')
  const [Version, getVersion] = useState(localStorage.getItem("Version")?localStorage.getItem("Version"):"1")
  const [ChVersion, getChVersion] = useState(localStorage.getItem("ChVersion"))
  const [Signs, getSings] = useState<IIULSign[]>((()=>{
    let sings:IIULSign[]=[]
    const SL=localStorage.getItem("Signs_Length")
    if (SL){
    const Signs_Length=Number(localStorage.getItem("Signs_Length"))
    const onFormatDate = (date?: Date): string => {
      const d= !date ? '' : `${'0'.repeat(2-date.getDate().toString().length)}${date.getDate()}.${'0'.repeat(2-(date.getMonth()+1).toString().length)}${(date.getMonth() + 1)}.${(date.getFullYear())}`;
      return d
    };
    if (!isNaN(Signs_Length)){
      sings=[]
      for (var i = 0; i < Signs_Length; i++) {
        sings.push({
          Work:localStorage.getItem(`Work_${i}`),
          FIO:localStorage.getItem(`FIO_${i}`),
          Date:onFormatDate(new Date())
          
        })
      }
    }else sings=[]
    }
    return sings
  })())
  const keys:IDropdownOption[]=[{ key: 'md5', text: 'MD5'},{ key: 'crc32', text: 'CRC32'}]
  initializeIcons(/* optional base url */);
  const onChangeObjTitle = (_: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newText: string): void => getObjTitle(newText)
  const onChangeDocCode = (_: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newText: string): void => getDocCode(newText)
  const onChangeChapter = (_: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newText: string): void => getChapter(newText)
  const onChangePart = (_: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newText: string): void => getPart(newText)
  const onChangeFileName = (_: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newText: string): void => getFileName(newText)
  const onChangeVersion = (_: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newText: string): void => getVersion(newText)
  const onChangeChVersion = (_: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newText: string): void => getChVersion(newText)
  const onChangeAlgoritm = (_event: React.FormEvent<HTMLDivElement>, item: IDropdownOption): void => getAlgoritm(String(item.key))
  const UpdatelocalStorage=()=>{
    localStorage.clear()
    if (ObjTitle) localStorage.setItem('ObjTitle', ObjTitle); 
    if (DocCode) localStorage.setItem('DocCode', DocCode); 
    if (Chapter) localStorage.setItem('Chapter', Chapter); 
    if (FileName) localStorage.setItem('FileName', FileName); 
    if (Part) localStorage.setItem('Part', Part); 
    if (Algoritm) localStorage.setItem('Algoritm', Algoritm); 
    if (Version) localStorage.setItem('Version', Version); 
    if (ChVersion) localStorage.setItem('ChVersion', ChVersion); 
    const cursings=[...Signs].filter(item=>item.Deleted!==true)
    localStorage.setItem(`Signs_Length`, String(cursings.length)); 
    for (var i = 0; i < cursings.length; i++) {
      if (Signs[i].Work) localStorage.setItem(`Work_${i}`, Signs[i].Work); 
      if (Signs[i].FIO) localStorage.setItem(`FIO_${i}`, Signs[i].FIO); 
    }
    //localStorage.setItem('Signs', JSON.stringify(Signs)); 
    
  }
  return (
    <div  >
      
      <div className='iul'>
      <YMInitializer accounts={[91598195]} />
          <form>
              <TooltipHost
              content="Укажите наименоване объекта строительства. Отображается в заголовке ИУЛ"
              // This id is used on the tooltip itself, not the host
              // (so an element with this id only exists when the tooltip is shown)
              id={tooltipId}
              calloutProps={calloutProps}
            >
              <TextField     
                label="Наименование объекта строительства" 
                value={ObjTitle}
                onChange={onChangeObjTitle}
                aria-describedby={tooltipId}
              />
            </TooltipHost>
             <Stack  horizontal wrap tokens={stackTokens}  {...columnProps}>
              <Stack.Item styles={stackItemStyles}>                
                  <TooltipHost
                    content="Укажите обозначение документа включаемого в ИУЛ"
                    // This id is used on the tooltip itself, not the host
                    // (so an element with this id only exists when the tooltip is shown)
                    id={tooltipId1}
                    calloutProps={calloutProps}
                  >
                    <TextField     
                      label="Обозначение документа" 
                      value={DocCode}
                      onChange={onChangeDocCode}
                      aria-describedby={tooltipId1}
                    />
                  </TooltipHost>
                </Stack.Item>
                <Stack.Item grow={4} styles={stackItemStyles}>
                  <TooltipHost
                      content="Укажите номер и наименование раздела проектной документации включемого в ИУЛ"
                      // This id is used on the tooltip itself, not the host
                      // (so an element with this id only exists when the tooltip is shown)
                      id={tooltipId2}
                      calloutProps={calloutProps}
                    >
                    <TextField     
                      label="Раздел" 
                      value={Chapter}
                      onChange={onChangeChapter}
                      aria-describedby={tooltipId2}
                    />
                  </TooltipHost>
                </Stack.Item>
                  <Stack.Item grow={2} styles={stackItemStyles}>
                  <TooltipHost
                        content="Укажите наименование и номер части проектной документации включемой в ИУЛ"
                        // This id is used on the tooltip itself, not the host
                        // (so an element with this id only exists when the tooltip is shown)
                        id={tooltipId3}
                        calloutProps={calloutProps}
                      >
                    <TextField     
                      label="Часть" 
                      value={Part}
                      onChange={onChangePart}
                      aria-describedby={tooltipId3}
                    />
                  </TooltipHost>
                </Stack.Item>
             </Stack>
             <Stack  horizontal  wrap  tokens={stackTokens}  {...columnProps}>
              <Stack.Item grow styles={stackItemStyles}>
              <TooltipHost
                  content="Укажите название документа. Это название будет использовано как название файла."
                  // This id is used on the tooltip itself, not the host
                  // (so an element with this id only exists when the tooltip is shown)
                  id={tooltipId4}
                  calloutProps={calloutProps}
                >
                <TextField     
                label="Название документа" 
                value={FileName}
                onChange={onChangeFileName}
                aria-describedby={tooltipId4}
              />
              </TooltipHost>

                </Stack.Item>
                <Stack.Item grow styles={stackItemStyles}>
                  <TooltipHost
                      content="Укажите алгоритм контрольной суммы."
                      // This id is used on the tooltip itself, not the host
                      // (so an element with this id only exists when the tooltip is shown)
                      id={tooltipId6}
                      calloutProps={calloutProps}
                    >
                   <Dropdown
                      label="Алгоритм"
                      options={keys}
                      selectedKey={Algoritm}
                      onChange={ onChangeAlgoritm}
                      aria-describedby={tooltipId6}
                    />

                  </TooltipHost>
              
                </Stack.Item>
                <Stack.Item grow styles={stackItemStyles}>
                  <SpinButton
                    label="Версия"
                    labelPosition={Position.top}
                    min={1}
                    max={10000}
                    step={1}
                    styles={spinstyles}
                    value={Version}
                    onChange={onChangeVersion}
                  />
                </Stack.Item>
                <Stack.Item grow styles={stackItemStyles}>
                <SpinButton
                  label="Номер изменения"
                  labelPosition={Position.top}
                  defaultValue="0"
                  min={0}
                  max={10000}
                  step={1}
                  styles={spinstyles}
                  value={ChVersion}
                  onChange={onChangeChVersion}
                />
                </Stack.Item>
             </Stack>
             <div className='App' >
              <br/>
              <TooltipHost
                  content="Выберите файлы включаемые в ИУЛ. Контрольные суммы и размер файла будут рассчитаны автоматически."
                  // This id is used on the tooltip itself, not the host
                  // (so an element with this id only exists when the tooltip is shown)
                  id={tooltipId5}
                  calloutProps={calloutProps}
                >
                <UploadFiles
                  onUpload={(files)=>{getFiles(files)}}
                  aria-describedby={tooltipId5}
                  />
              </TooltipHost>
              </div> 
              <br/>
              <IulSigns
              values={Signs}
              onChange={(sings)=>{
                  //UpdatelocalStorage()
                  getSings([...sings].filter(item=>item.Deleted!==true))
                }
              }
              />                      
          </form>
          </div>

          
          
          <DownloadFilePdfSelect
            onDownload={()=>UpdatelocalStorage()}
            state= { {title:FileName,o:ObjTitle, f:(props.demo)?(()=>{
              return Files.map(item=>{
                const beginmd5=item.md5.substring(0,9)
                const endmd5=item.md5.substring(23)
                const begincrc32=item.crc32.substring(0,2)
                const endcrc32=item.crc32.substring(6)
                item.md5=`${beginmd5}<<demoversion>>${endmd5}`
                item.crc32=`${begincrc32}demo${endcrc32}`
                return item
            })
          })():Files
              ,s:Signs}}
            docs={[
              {Title:FileName,  Demomark:(props.demo)?"ОБРАЗЕЦ ДОКУМЕНТА":undefined, Rows:
            [
            {Cells:[
                {Labels:[{Caption:"Информационно-удостоверяющий лист",Aligment:text_align_enum.Center,Bold:true,FontSize:14}],Border:true}
              ],IndentY:50
            },
            {
              Cells:[ {Labels:[{Caption:ObjTitle,Aligment:text_align_enum.Center}]}], IndentY:5
            },
            {
              Cells:[
                {Labels:[{Caption:`Номер п/п`,Aligment:text_align_enum.Center, Bold:true}],Border:true, Width:46},
                {Labels:[{Caption:`Обозначение документа`,Aligment:text_align_enum.Center, Bold:true}],Border:true,Width:100},
                {Labels:[{Caption:`Наименование изделия, наименование документа`,Aligment:text_align_enum.Center, Bold:true}],Border:true,Width:150},
                {Labels:[{Caption:`Версия`,Aligment:text_align_enum.Center, Bold:true}],Border:true,Width:55},
                {Labels:[{Caption:`Номер последнего изменения`,Aligment:text_align_enum.Center, Bold:true}],Border:true},
              ]
            },
            {
              Cells:[
                {Labels:[{Caption:` 1 `,Aligment:text_align_enum.Center}],Border:true,Width:46},
                {Labels:[{Caption:DocCode,Aligment:text_align_enum.Center}],Border:true,Width:100},
                {Border:true,Width:150,Labels:[
                  {Caption:Chapter , Bold:true,FontSize:11},
                  {Caption:Part ,FontSize:11},
                ]},
                {Labels:[{Caption:Version,Aligment:text_align_enum.Center}],Border:true,Width:55},
                {Labels:[{Caption:ChVersion,Aligment:text_align_enum.Center}],Border:true},
              ]
            },
            ...(()=>{
              let insrows:IPDFRow[]=[]
              for (const file of Files) {
                insrows.push({
                  Cells:[
                    {Labels:[{Caption:(Algoritm==='md5')?'MD5':'CRC32',Aligment:text_align_enum.Center,Bold:true}],Border:true,Width:100},
                    {Labels:[{Caption:file.number,Aligment:text_align_enum.Center}],Border:true,Width:100},
                    {Labels:[{Caption:(Algoritm==='md5')?file.md5:file.crc32,Aligment:text_align_enum.Center}],Border:true},
                  ]
              })}
              if (insrows.length>0) insrows[0].IndentY=10
              return insrows
            })(),
            {
              Cells:[
                {Labels:[{Caption:`Номер п/п`,Aligment:text_align_enum.Center, Bold:true}],Border:true, Width:46},
                {Labels:[{Caption:`Наименование файла`,Aligment:text_align_enum.Center, Bold:true}],Border:true,Width:200},
                {Labels:[{Caption:`Дата и время последнего изменения файла`,Aligment:text_align_enum.Center, Bold:true}],Border:true,Width:150},
                {Labels:[{Caption:`Размер файла (Байт)`,Aligment:text_align_enum.Center, Bold:true}],Border:true},
              ],IndentY:10
            },
            ...(()=>{
              let items:IPDFRow[]=[]
              for (const file of Files) {
                items.push({
                  Cells:[
                    {Labels:[{Caption:file.number,Aligment:text_align_enum.Center}],Border:true, Width:46},
                    {Labels:[{Caption:file.filename}],Border:true,Width:200},
                    {Labels:[{Caption:file.lastmodifed,Aligment:text_align_enum.Center}],Border:true,Width:150},
                    {Labels:[{Caption:file.size,Aligment:text_align_enum.Center}],Border:true},
                  ]
              })}
              if (items.length>0) items[0].IndentY=5
              return items
             
            })(),
            {
              Cells:[
                {Labels:[{Caption:`Характер работы`,Aligment:text_align_enum.Center, Bold:true}],Border:true},
                {Labels:[{Caption:`ФИО`,Aligment:text_align_enum.Center, Bold:true}],Border:true},
                {Labels:[{Caption:`Подпись`,Aligment:text_align_enum.Center, Bold:true}],Border:true},
                {Labels:[{Caption:`Дата подписи`,Aligment:text_align_enum.Center, Bold:true}],Border:true},
              ],IndentY:10
            },
            ...(()=>{
              let items:IPDFRow[]=[]
              for (var i = 0; i < Signs.length; i++) {
                items.push({
                  Cells:[
                    {Labels:[{Caption:Signs[i].Work,Aligment:text_align_enum.Center}],Border:true},
                    {Labels:[{Caption:Signs[i].FIO}],Border:true},
                    {Labels:[{Image:{Buffer:Signs[i].Buffer,Type:Signs[i].FileType}}],Border:true},
                    {Labels:[{Caption:Signs[i].Date,Aligment:text_align_enum.Center}],Border:true},
                  ]
                })
              }
              if (items.length>0) items[0].IndentY=5
              return items
            })(),
          ],Footer:[
            {Cells:[
              {Labels:[{Caption:"Информационно-удостоверяющий лист",Aligment:text_align_enum.Center, Bold:true}],Border:true,Width:100},
              {Labels:[{Caption:FileName,Aligment:text_align_enum.Center, Bold:true}],Border:true,Width:300},
              {Labels:[
                {Caption:"Лист",Aligment:text_align_enum.Center, Bold:true,Border:true},
                {Caption:"#Page#",Aligment:text_align_enum.Center},
              ],Border:true},
              {Labels:[
                {Caption:"Листов",Aligment:text_align_enum.Center, Bold:true,Border:true},
                {Caption:"#Pages#",Aligment:text_align_enum.Center},
              ],Border:true}
            ],IndentY:50
          },
          ]},
          {Title:FileName,  Demomark:(props.demo)?"ОБРАЗЕЦ ДОКУМЕНТА":undefined, Rows:
            [
            {Cells:[
                {Labels:[{Caption:"Информационно-удостоверяющий лист",Aligment:text_align_enum.Center,Bold:true,FontSize:14}]}
              ],IndentY:50
            },
            {
              Cells:[ 
                {Labels:[{Caption:`Наименование объекта`,Aligment:text_align_enum.Center, Bold:true}],Border:true, Width:146},
                {Labels:[{Caption:ObjTitle,Aligment:text_align_enum.Center}],Border:true}
              ]
                , IndentY:5
            },
            {
              Cells:[
                {Labels:[{Caption:`Номер п/п`,Aligment:text_align_enum.Center, Bold:true}],Border:true, Width:46},
                {Labels:[{Caption:`Обозначение документа`,Aligment:text_align_enum.Center, Bold:true}],Border:true,Width:100},
                {Labels:[{Caption:`Наименование изделия, наименование документа`,Aligment:text_align_enum.Center, Bold:true}],Border:true,Width:150},
                {Labels:[{Caption:`Версия`,Aligment:text_align_enum.Center, Bold:true}],Border:true,Width:55},
                {Labels:[{Caption:`Номер последнего изменения`,Aligment:text_align_enum.Center, Bold:true}],Border:true},
              ]
            },
            {
              Cells:[
                {Labels:[{Caption:` 1 `,Aligment:text_align_enum.Center}],Border:true,Width:46},
                {Labels:[{Caption:DocCode,Aligment:text_align_enum.Center}],Border:true,Width:100},
                {Border:true,Width:150,Labels:[
                  {Caption:Chapter , Bold:true,FontSize:11},
                  {Caption:Part ,FontSize:11},
                ]},
                {Labels:[{Caption:Version,Aligment:text_align_enum.Center}],Border:true,Width:55},
                {Labels:[{Caption:ChVersion,Aligment:text_align_enum.Center}],Border:true},
              ]
            },
            {
              Cells:[
                {Labels:[{Caption:`Тип контрольной суммы`,Aligment:text_align_enum.Center, Bold:true}],Border:true,Width:250},
                {Labels:[{Caption:(Algoritm==='md5')?'MD5':'CRC32',Aligment:text_align_enum.Center}],Border:true},
              ],IndentY:10
            },
            {
              Cells:[
                {Labels:[{Caption:`Наименование файла`,Aligment:text_align_enum.Center, Bold:true}],Border:true,Width:230},
                {Labels:[{Caption:`Дата и время последнего изменения файла`,Aligment:text_align_enum.Center, Bold:true}],Border:true,Width:80},
                {Labels:[{Caption:`Размер файла (Байт)`,Aligment:text_align_enum.Center, Bold:true}],Border:true,Width:80},
                {Labels:[{Caption:`Значение контрольной суммы`,Aligment:text_align_enum.Center, Bold:true}],Border:true},
              ],IndentY:10
            },
            ...(()=>{
              let items:IPDFRow[]=[]
              for (const file of Files) {
                items.push({
                  Cells:[
                    {Labels:[{Caption:file.filename}],Border:true,Width:230},
                    {Labels:[{Caption:file.lastmodifed,Aligment:text_align_enum.Center}],Border:true,Width:80},
                    {Labels:[{Caption:file.size,Aligment:text_align_enum.Center}],Border:true,Width:80},
                    {Labels:[{Caption:(Algoritm==='md5')?file.md5:file.crc32,Aligment:text_align_enum.Center}],Border:true},
                  ]
              })}
              if (items.length>0) items[0].IndentY=5
              return items
             
            })(),
            {
              Cells:[
                {Labels:[{Caption:`Характер работы`,Aligment:text_align_enum.Center, Bold:true}],Border:true},
                {Labels:[{Caption:`ФИО`,Aligment:text_align_enum.Center, Bold:true}],Border:true},
                {Labels:[{Caption:`Подпись`,Aligment:text_align_enum.Center, Bold:true}],Border:true},
                {Labels:[{Caption:`Дата подписи`,Aligment:text_align_enum.Center, Bold:true}],Border:true},
              ],IndentY:10
            },
            ...(()=>{
              let items:IPDFRow[]=[]
              for (var i = 0; i < Signs.length; i++) {
                items.push({
                  Cells:[
                    {Labels:[{Caption:Signs[i].Work,Aligment:text_align_enum.Center}],Border:true},
                    {Labels:[{Caption:Signs[i].FIO}],Border:true},
                    {Labels:[{Image:{Buffer:Signs[i].Buffer,Type:Signs[i].FileType}}],Border:true},
                    {Labels:[{Caption:Signs[i].Date,Aligment:text_align_enum.Center}],Border:true},
                  ]
                })
              }
              if (items.length>0) items[0].IndentY=5
              return items
            })(),
          ],Footer:[
            {Cells:[
              {Labels:[{Caption:"Информационно-удостоверяющий лист",Aligment:text_align_enum.Center, Bold:true}],Border:true,Width:100},
              {Labels:[{Caption:FileName,Aligment:text_align_enum.Center, Bold:true}],Border:true,Width:300},
              {Labels:[
                {Caption:"Лист",Aligment:text_align_enum.Center, Bold:true,Border:true},
                {Caption:"#Page#",Aligment:text_align_enum.Center},
              ],Border:true},
              {Labels:[
                {Caption:"Листов",Aligment:text_align_enum.Center, Bold:true,Border:true},
                {Caption:"#Pages#",Aligment:text_align_enum.Center},
              ],Border:true}
            ],IndentY:50
          },
          ]}
        ]}
          />
          
    </div>
  );
};