/* eslint-disable no-useless-escape */
/* eslint-disable no-loop-func */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { PDFDocument, PDFImage, PDFPage, PageSizes, degrees, rgb } from "pdf-lib";
import fontkit from "@pdf-lib/fontkit";
/* eslint-disable @typescript-eslint/no-redeclare */
export const text_align_enum={
    Left:0,
    Center:1
}
export interface IPDFRow {
    Cells: IPDFCell[]
    IndentY?: number
  }
  export interface IPDFCell {
    Labels?: IPDFText[]
    Width?:number
    Border?:boolean
}
export interface IPDFText {
    Caption?:string
    Bold?:boolean
    FontSize?:number
    Aligment?:number
    Border?:boolean
    Image?:IPDFImage
}
export interface IPDFImage {
    Buffer?:ArrayBuffer
    Type?:string
}
export interface IPDFDoc {
    Title: string
    Rows?: IPDFRow[]
    Footer?: IPDFRow[]
    Demomark?:string|undefined
  }

export const CreatePDF= async (pdfdoc:IPDFDoc): Promise<string>=>{
    const url2 = './times.ttf'
    const urlbd = './timesbd.ttf'
    const lineheight=16
    const title = pdfdoc.Title?pdfdoc.Title:" "
    const rows:IPDFRow[] = pdfdoc.Rows
    const footer =pdfdoc.Footer
    const demomark:string|undefined =pdfdoc.Demomark
    const fontBytes = await fetch(url2).then(res => res.arrayBuffer())
    const fontBytesbd = await fetch(urlbd).then(res => res.arrayBuffer())
    const doc:PDFDocument = await PDFDocument.create()
    doc.setTitle(title,{ showInWindowTitleBar: true })
    let page:PDFPage
    
    doc.registerFontkit(fontkit);
    const InsertDemoMark=(page:PDFPage,text:string|undefined=undefined)=>{        
        if (text){
            const { width, height } = page.getSize()
            let demomarkwidth = fnt.widthOfTextAtSize(text, 50);
            page.drawText(text, {
                x: (width -demomarkwidth)/2+50,
                y: height/ 2 + 200,
                size: 50,
                font: fnt,
                color: rgb(0.95, 0.1, 0.1),
                rotate: degrees(-45),
            })    
        }
    }
    const fnt= await( async(doc)=>{
        if (fontBytes) {
            return await doc.embedFont(fontBytes);
        }})(doc)
    const fntbold= await( async(doc)=>{
        if (fontBytesbd) {
           // doc.registerFontkit(fontkit);
       return await doc.embedFont(fontBytesbd);
        }})(doc)
    if (rows.length>0)  {
        page = doc.addPage(PageSizes.A4);
        let maximglength:number=0 //Если Cell содержит изображение(подписи), то все последующие строки будут иметь такую высоту 
        const { width, height } = page.getSize()
       InsertDemoMark(page,demomark)
        const textlines =(text:string, cellwidth:number, width:number)=>{
            let textlength=cellwidth/(width/text.length+1)
            textlength=Math.round(textlength)
            const re = new RegExp(`^.{1,${textlength}}$|[ -].{1,${textlength}}[ -]|\\S.{1,${textlength}}$|\\S.{1,${textlength}}[ -]|.{1,${textlength}}`, 'gm');
            return text.match(re)
        }
        const cellheight= async(row,cell)=>{
            let length=0
            const cellwidth=cell.Width?cell.Width:(()=>{
                let pwidth=width-100  
                let width_count=0
                for (const cell of row.Cells) {
                    if (cell.Width) {
                        pwidth-=cell.Width
                        width_count++
                    }
                }
                if (pwidth<10)pwidth=10
                return pwidth/(row.Cells.length-width_count)
            })()                                 
            for (const label of cell.Labels) {
                const font =label.Bold?fntbold:fnt
                const fontsize=label.FontSize?label.FontSize:12
                const text=label.Caption?label.Caption:' '
                let textWidth = font.widthOfTextAtSize(text, fontsize);
                const celllines=textlines(text,cell.Width?cell.Width:(()=>{
                    let pwidth=width-100  
                    let width_count=0
                    for (const curcell of row.Cells) {
                        if (curcell.Width) {
                            pwidth-=curcell.Width
                            width_count++
                        }
                    }
                    if (pwidth<10)pwidth=10
                    return pwidth/(row.Cells.length-width_count)
                })(),textWidth).length
                length+=celllines
                if (label.Image){
                    const buffer=label.Image.Buffer
                    if (buffer){
                    const imgtype=label.Image.Type
                    let curImage:PDFImage
                    if (imgtype==='image/png') curImage = await doc.embedPng(buffer)
                        else curImage = await doc.embedJpg(buffer)
                    const jpgDims = curImage.scaleToFit(cellwidth-50,cellwidth-50) 
                    let imglength=Math.round(jpgDims.height/lineheight)
                    if (imglength>maximglength)maximglength=imglength
                     else imglength=maximglength
                    if (imglength>length)length=imglength
                }else length=maximglength
                }
            }
            return length
        }
        const rowheight=async(row)=>{
            let maxlength=0 
                        
            for (const cell of row.Cells) {
                const  curlength=await cellheight(row,cell) 
                if (curlength>maxlength) maxlength=curlength
            }
            return maxlength
        }
        const footerheight=await (async(rows)=>{
            let result:number=0
            for (const row of rows) {
                const rheight=await rowheight(row)
                result +=rheight
            }
            result*=lineheight
            return result+100
        })(footer)
        const tableheight=await(async(rows)=>{
            let result:number=0
            for (const row of rows) {
                const rheight=await rowheight(row)
                result +=(rheight*lineheight)
                if (row.IndentY) result +=row.IndentY
            }
            return result
        })(rows)
        const pagecount=Math.ceil(tableheight/((height-footerheight)))
     // pagecount=(pagecount===0)?1:pagecount
        const drawTable=async(rows, curpos:number=0)=>{
            const IsNotFooter =(curpos===0)
            let page_height=height
            if (IsNotFooter)  page_height-=(footerheight)
            for (const row of rows) {
                if (row.IndentY) curpos+=row.IndentY
                let startpos=curpos
                let x=0
                const rheight=await rowheight(row)
                if ((IsNotFooter)&&((startpos+rheight*lineheight-40)>page_height)) {
                    page = doc.addPage(PageSizes.A4);
                    InsertDemoMark(page,demomark)
                    await drawTable(footer, height-footerheight)
                    page_height=height-footerheight
                    curpos=40
                    startpos=curpos
                }  
                              
                for (const cell of row.Cells) {
                    const border=cell.Border?cell.Border:false
                    const cellwidth=cell.Width?cell.Width:(()=>{
                        let pwidth=width-100  
                        let width_count=0
                        for (const cell of row.Cells) {
                            if (cell.Width) {
                                pwidth-=cell.Width
                                width_count++
                            }
                        }
                        if (pwidth<10)pwidth=10
                        return pwidth/(row.Cells.length-width_count)
                    })()
                    curpos=startpos
                    let cheight=await cellheight(row, cell)
                    if (cell.Labels.length>1)cheight=rheight
                    for (const label of cell.Labels) {
                        const font =label.Bold?fntbold:fnt
                        const fontsize=label.FontSize?label.FontSize:12
                        const border=label.Border?label.Border:false
                        const aligment=label.Aligment?label.Aligment:0
                        let curtext:string=label.Caption?label.Caption:' ' 
                        curtext=curtext.replace('#Page#',String(doc.getPageCount()))
                        curtext=curtext.replace('#Pages#',String(pagecount))   
                        let textWidth = font.widthOfTextAtSize(curtext, fontsize);
                        const lines=textlines(curtext,cellwidth, textWidth)   
                        lines.forEach(async textLine=>{
                                const lineWidth = font.widthOfTextAtSize(textLine, fontsize);
                                curpos+=lineheight 
                                page.drawText(textLine, {
                                    x: (aligment===1)?((cellwidth -lineWidth)/2+50)+x:x+55,
                                    y: page_height+(IsNotFooter? footerheight:0) - curpos-( rheight-cheight)/2*lineheight,
                                    size: fontsize,
                                    font: font,
                                    color: rgb(0,0,0),
                                    lineHeight: lineheight,
                                });
                                
                            
                            })
                            if (label.Image){
                                const buffer=label.Image.Buffer
                                if (buffer){
                                    const imgtype=label.Image.Type
                                    let curImage:PDFImage
                                    if (imgtype==='image/png') curImage = await doc.embedPng(buffer)
                                        else curImage = await doc.embedJpg(buffer)
                                        const jpgDims = curImage.scaleToFit(cellwidth-50,cellwidth-50)
                                        page.drawImage(curImage, {
                                        x: ((cellwidth -jpgDims.width)/2+50)+x,
                                        y: page_height+(IsNotFooter?footerheight:0) - curpos-(rheight-cheight)/2*lineheight-cheight*lineheight+10,
                                        width: jpgDims.width,
                                        height: jpgDims.height,
                                    })
                                }
                                
                            }
                        if (border) page.drawRectangle({
                            x: x+50,
                            y: page_height+(IsNotFooter?footerheight:0) - curpos-(rheight-cheight)-4,
                            width: cellwidth,
                            height: (rheight-cheight)*lineheight,
                            borderWidth: 1,
                            borderColor: rgb(0, 0, 0),
                            opacity: 0.5,
                            borderOpacity: 0.75,
                            })      
                     }
                    if (border) page.drawRectangle({
                        x: x+50,
                        y: page_height +(IsNotFooter?footerheight:0)- startpos-4,
                        width: cellwidth,
                        height: -rheight*lineheight,
                        borderWidth: 1,
                        borderColor: rgb(0, 0, 0),
                        opacity: 0.5,
                        borderOpacity: 0.75,
                        })      
                        x+=cellwidth
                }
                curpos=startpos+ rheight*lineheight
            }
            
        
        }
        await drawTable(footer, height- footerheight)
        await drawTable(rows)
    return await doc.saveAsBase64({ dataUri: true });
}
}

/*export const InsertText=(text:string, font, page:any, left:number, top:number, width :number, aligment:number=0, bold:boolean=false)=>{
    const fontsize=bold?18:16
    let textWidth = font.widthOfTextAtSize(text, fontsize);
    const result = page.drawText(text, {
        x: (page.getWidth() / 2) - (textWidth / 2),
        y: page.getHeight() - top,
        size: fontsize,
        font: font,
        color: rgb(0,0,0),
      });
}*/
